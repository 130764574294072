import { useEffect, useState, Fragment } from "react";

// UI IMPORT
import {
  Button,
  ControlledDatePicker,
  Export,
  Input,
  Select,
  Table,
  TableRow,
} from "../../ui";

// PROJECT IMPORT
import { useGetStatementDataMutation } from "./statementApi";
import { useStatementData } from "./statementSlice";
import { FILTER } from "../../constant";
import { filterOptions } from "./mock";

// THIRD - PARTY IMPORT
import { useForm } from "react-hook-form";
import { dateFormatter } from "../../helperFunctions";

const Statement = () => {
  const [getStatementData, { isLoading }] = useGetStatementDataMutation();
  const [filter, setFilter] = useState(FILTER);
  const [isError, setIsError] = useState<any>();
  const statementData = useStatementData();

  const columns = [
    { title: "Date", name: "created_at" },
    { title: "Transcation ID", name: "tranId" },
    { title: "Amount", name: "amount" },
    { title: "New Balance", name: "newBal" },
    { title: "Bank UTR", name: "bank_rrn" },
    { title: "Type", name: "type" },
  ];

  const { register, handleSubmit, reset, getValues, control } = useForm({});

  const onGetData = () => {
    const values = getValues();
    let payload: any = {};
    if (filter?.isFilter) {
      payload = {
        ...filter,
        filter_data: {
          InitiateDate: dateFormatter(values?.date?.[0], "start"),
          FinalizeDate: dateFormatter(values?.date?.[1], "end"),
          [values.transaction_id]: values?.search,
        },
      };
    } else {
      payload = { ...filter };
    }
    delete payload["isFilter"];
    getStatementData(payload);
  };

  const onSubmit = () => {
    setFilter({ ...FILTER, isFilter: true });
  };

  useEffect(() => {
    if (
      (isError?.filter && isError?.value) ||
      // eslint-disable-next-line
      (isError?.value == "" && isError?.value == "") ||
      // eslint-disable-next-line
      isError?.value == undefined
    ) {
      onGetData();
    } else {
    }
    // eslint-disable-next-line
  }, [filter]);

  return (
    <>
      <form
        className="flex justify-between px-1"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-wrap gap-2 items-start">
          <div>
            <div className="flex flex-wrap gap-2">
              <Select
                name="transaction_id"
                label="Filter"
                options={filterOptions}
                register={register}
                onChange={(e) =>
                  setIsError({ ...isError, filter: e.target.value })
                }
              />
              <Input
                className="me-2"
                name="search"
                placeholder="Enter Search Value"
                label="Search"
                register={register}
                onChange={(e) =>
                  setIsError({ ...isError, value: e.target.value })
                }
              />
            </div>
            {!isError?.filter && isError?.value ? (
              <div className="text-red-500 mt-1">*Please Select Filter</div>
            ) : null}
          </div>
          <ControlledDatePicker
            name="date"
            label="Date"
            placeholder="Date"
            options={{
              mode: "range",
            }}
            control={control}
          />
          <Button type="submit" className="mt-[22px] !h-9 !text-sm">
            Apply
          </Button>
          <Button
            variant="Transaparent"
            className="mt-[22px] !h-9 !text-sm"
            onClick={() => {
              reset();
              setFilter({ ...FILTER });
            }}
          >
            Clear
          </Button>
        </div>

        <div className="flex items-center">
          <Export
            className="mt-[22px] !h-9 !text-sm"
            filename="download.csv"
            data={statementData?.data || []}
            headers={
              columns?.map((val) => ({
                label: val?.title,
                key: val?.name,
              })) || []
            }
          >
            Download
          </Export>
        </div>
      </form>
      <div className="">
        <div className="App mt-3">
          <Table
            columns={columns}
            isLoading={isLoading}
            data={statementData?.data}
            isExpendable={false}
            count={statementData?.total_item}
            pagination={filter}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
              });
            }}
          >
            {statementData?.data?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    columns={columns}
                    isExpendable={false}
                    item={item}
                  />
                </Fragment>
              );
            })}
          </Table>
        </div>
      </div>
    </>
  );
};

export default Statement;
