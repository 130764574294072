import { Fragment, useEffect } from "react";

// PROJECT IMPORT
import { useAppDispatch } from "../../utills/hooks";
import { useUser } from "../auth/authSlice";
import Navigator from "./Navigator";
import { toggleDrawer, useIsDrawerOpen } from "../common/commonSlice";
import { useMedia } from "../../components";

const Sidebar = () => {
  const user = useUser();
  const isDrawerOpen = useIsDrawerOpen();
  const { media } = useMedia();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(toggleDrawer(media.width < 992 ? false : true));
    // eslint-disable-next-line
  }, [media]);

  const logo: any = process.env.REACT_APP_INNER_LOGO;

  return (
    <>
      <div className="mainSidebar">
        <div
          className={`sideBar relative whitespace-nowrap ${isDrawerOpen ? "w-[220px]" : "w-[75px]"
            } ${media.width < 768 && !isDrawerOpen ? "hidden" : ""}`}
        >
          <div className="sideBarLogo boxCenter">
            {isDrawerOpen ? (
              <span className="text-white fs-3">
                <img
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(logo)}`}
                  className="my-2 m-auto w-28 mt-2"
                  alt="Logo"
                />
              </span>
            ) : null}
          </div>
          {/* ======= Navigation ======= */}
          <div className="navigation">
            <nav>
              {/* About */}
              {user?.sidebar?.map((res: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <Navigator
                      name={
                        res?.name === "Internal Fund Transfer"
                          ? "Fund Transfer"
                          : res?.name
                      }
                      path={res?.route}
                      navIcon={res?.icon_class}
                      onClick={() => {
                        if (media.width < 768) {
                          dispatch(toggleDrawer(false));
                        }
                      }}
                    >
                      {res?.subMenu &&
                        res?.subMenu?.map((subMenu: any) => {
                          return (
                            <Navigator
                              subName={subMenu.subName}
                              subPath={subMenu.subPath}
                              onClick={subMenu.onClick}
                            />
                          );
                        })}
                    </Navigator>
                  </Fragment>
                );
              })}
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
