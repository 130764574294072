// THIRD - PARTY IMPORT
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
ChartJS.defaults.datasets.bar.maxBarThickness = 40;
ChartJS.defaults.borderColor = "#00000008";

interface ChartOptions {
  options: any;
  data: any;
  width?: number;
  label?: string;
  height?: number;
  isLoading?: boolean;
}

const Chart = (props: ChartOptions) => {
  const {
    options,
    data,
    height = 80,
    label,
    isLoading = false,
    ...rest
  } = props;
  return (
    <>
      {
          // isLoading ? (
          //   <div
          //     className="w-full flex items-center justify-center"
          //     style={{
          //       height: height * 3,
          //     }}
          //   >
          //     <Loader />
          //   </div>
          // ) :
        data?.datasets && !isLoading ? (
          <div className="px-2 py-2 space-y-4">
            {label ? (
              <span className="font-bold text-gray-600">{label}</span>
            ) : null}
            <Bar options={options} data={data} height={height} {...rest} />
          </div>
        ) : null
      }
    </>
  );
};

export default Chart;
